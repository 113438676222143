import React, { useEffect, useRef, useState } from 'react';

function formatDates(dateArray) {
  let copyDateArray = dateArray;
  return copyDateArray.map(date =>
    new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: '2-digit'
    })
  );
}
const EChartsComponent = ({ analyticData }) => {
  const [dynamicWidth, setDynamicWidth] = useState("100%");
  const chartRef = useRef(null);

  useEffect(() => {
    if (Object.keys(analyticData).length > 0) {

      if (analyticData.date.length > 0) {
        setDynamicWidth(`${analyticData.date.length * 200}px`)
      }

      const loadJsPDF = () => {
        return new Promise((resolve) => {
          const script = document.createElement('script');
          script.src = 'https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.4.0/jspdf.umd.min.js';
          script.onload = () => {
            resolve(window.jspdf);
          };
          document.body.appendChild(script);
        });
      };

      const initializeChart = async () => {
        const dom = chartRef.current;
        const myChart = window.echarts.init(dom, null, {
          renderer: 'canvas',
          useDirtyRect: false
        });

        const jsPDF = await loadJsPDF();

        const option = {
          title: {
            text: ''
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            orient: 'horizontal',
            bottom: 0,
            textStyle: {
              color: '#000'
            },
            show: false
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {
                type: 'jpeg',
                name: 'chart_image',
                title: 'Save as JPEG',
                backgroundColor: '#fff',
                pixelRatio: 2
              }
            }
          },
          grid: {
            show: true,
            borderColor: '#ddd',
            borderWidth: 1,
            containLabel: true,
            left: '5%',   // Reducing left margin
            right: '5%',  // Reducing right margin
            bottom: '10%', // Adjusting bottom margin
            top: '10%',    // Adju
          },
          // xAxis: {
          //   type: 'category',
          //   boundaryGap: false,
          //   // data: [
          //   //   'Jan 01, 24',
          //   //   'Jan 15, 24',
          //   //   'Feb 01, 24',
          //   //   'Feb 15, 24',
          //   //   'Mar 02, 24',
          //   //   'Mar 15, 24',
          //   //   'Apr 15, 24'
          //   // ]
          //   data: analyticData.date ? formatDates(analyticData.date) : [],
          //   axisLabel: {
          //     formatter: '{value}'
          //   }
          // },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: analyticData.date ? formatDates(analyticData.date) : [],
            axisLabel: {
              formatter: function(value, index) {
                // another array 'count' to show alongside dates
                const counts = analyticData.count || []; // Add a corresponding counts array
                const count = counts[index] || ''; // If count exists, use it; otherwise, empty string
                return `${value}\n\n${count} reviews`; // Display both date and count on new lines
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: [
            {
              name: 'Wellbeing',
              type: 'line',
              lineStyle: {
                color: '#8FD598',
                width: 2
              },
              data: analyticData.wellbeingScores ? analyticData.wellbeingScores : [],
              markPoint: {
                data: analyticData.wellbeingScores ? analyticData.wellbeingScores.map((value, index) => ({
                  coord: [index, value],
                  value: value
                })) : [],
                symbol: 'path://M 10 0 H 40 A 10 10 0 0 1 40 20 H 10 A 10 10 0 0 1 10 0 Z',
                symbolSize: [50, 20],
                symbolOffset: [0, '-10%'],
                itemStyle: {
                  borderRadius: 10,
                  color: '#8FD598'
                },
                label: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 12,
                  fontWeight: 'normal',
                  position: 'inside'
                }
              },
              itemStyle: {
                color: '#8FD598'
              }
            },
            {
              name: 'Resiliance',
              type: 'line',
              lineStyle: {
                color: '#d853ef',
                width: 2
              },
              data: analyticData.resilienceScores,
              markPoint: {
                data: analyticData.resilienceScores ? analyticData.resilienceScores.map((value, index) => ({
                  coord: [index, value],
                  value: value
                })) : [],
                symbol: 'path://M 10 0 H 40 A 10 10 0 0 1 40 20 H 10 A 10 10 0 0 1 10 0 Z',
                symbolSize: [50, 20],
                symbolOffset: [0, '-10%'],
                itemStyle: {
                  borderRadius: 10,
                  color: '#d853ef'
                },
                label: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 12,
                  fontWeight: 'normal',
                  position: 'inside'
                }
              },
              itemStyle: {
                color: '#d853ef'
              }
            },
            {
              name: 'Performance',
              type: 'line',
              lineStyle: {
                color: '#e08357',
                width: 2
              },
              data: analyticData.productivityScores,
              markPoint: {
                data: analyticData.productivityScores ? analyticData.productivityScores.map((value, index) => ({
                  coord: [index, value],
                  value: value
                })) : [],
                symbol: 'path://M 10 0 H 40 A 10 10 0 0 1 40 20 H 10 A 10 10 0 0 1 10 0 Z',
                symbolSize: [50, 20],
                symbolOffset: [0, '-10%'],
                itemStyle: {
                  borderRadius: 10,
                  color: '#e08357'
                },
                label: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 12,
                  fontWeight: 'normal',
                  position: 'inside'
                }
              },
              itemStyle: {
                color: '#e08357'
              }
            },
            {
              name: 'Average',//can i set dangeriouly set html here ?
              type: 'line',
              lineStyle: {
                color: '#f3d168',
                width: 2
              },
              data: analyticData.average,
              markPoint: {
                data: analyticData.average ? analyticData.average.map((value, index) => ({
                  coord: [index, value],
                  value: value
                })) : [],
                symbol: 'path://M 10 0 H 40 A 10 10 0 0 1 40 20 H 10 A 10 10 0 0 1 10 0 Z',
                symbolSize: [50, 20],
                symbolOffset: [0, '-10%'],
                itemStyle: {
                  borderRadius: 10,
                  color: '#f3d168'
                },
                label: {
                  show: true,
                  color: '#ffffff',
                  fontSize: 12,
                  fontWeight: 'normal',
                  position: 'inside'
                }
              },
              itemStyle: {
                color: '#f3d168'
              }
            },
          ]
        };

        if (option && typeof option === 'object') {
          myChart.setOption(option);
        }

        // Resize chart on window resize
        const handleResize = () => {
          if (myChart.current) {
            if (window.innerWidth <= 991) {
              chartRef.current.style.height = `600px`;
            } else {
              chartRef.current.style.height = "auto";
            }
            myChart.current.resize();
          }
        };

        window.addEventListener('resize', handleResize);

        // window.addEventListener('resize', myChart.resize);

        return () => {
          // window.removeEventListener('resize', myChart.resize);
          window.removeEventListener('resize', handleResize);
          if (myChart.current) {
            myChart.current.dispose();
          }
        };
      };

      initializeChart();
    }

  }, [analyticData, /* dynamicWidth */]);

  return (
    <div id="main" className="chart" ref={chartRef} />
  );
};

export default EChartsComponent;
